
@import patterns
@import url('../../node_modules/aos/dist/aos.css')

.text-python
  color: #3FB422

.text-python-hl
  color: #acc267

.text-hl
  color: #f92672

.caption
  color: #869ab8 !important
  font-size: 12px

.highlight-block
  background: #1e1e1e
  padding: 20px
  border-radius: 4px
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.25)

  pre, table
    background: #1e1e1e
    color: white

.highlight-block.limit-height
  overflow-y: scroll
  max-height: 700px

pre
  overflow: auto
  -ms-word-wrap: normal
  word-wrap: normal
  overflow-wrap: normal
  white-space: pre

\::-webkit-scrollbar
  width: 8px
  height: 8px

/* Track

\::-webkit-scrollbar-track
  background: transparent

/* Handle

\::-webkit-scrollbar-thumb
  background: #888

  &:hover
    background: #555

.box-blur
  -webkit-filter: blur(6px)
  filter: blur(6px)

.clickable
  cursor: pointer

.non-clickable
  cursor: auto